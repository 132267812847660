import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled, { isStyledComponent } from 'styled-components';
import { useState, useEffect } from 'react';
import Header from './Header';
import Background from '../assets/img/stadium.jpg';
import LVHPlaceholder from '../assets/img/lvh_placeholder.png';
import LVHIcon from '../assets/img/lvh.png';
import Email from '../assets/img/email.png';
import Ball from '../assets/img/ball.png';
import Phone from '../assets/img/phone-call.png';
import { useOutletContext } from "react-router-dom";
import {
  Outlet,
  Link,
  useLoaderData,
} from "react-router-dom"
import { useParams } from 'react-router-dom';

function Fechas() {
  const urlStates = useOutletContext();
  const [fechas, setFechas] = useState(useLoaderData()[1]);
  const idDivisional = useLoaderData()[0];
  
  const FechasDesktop = styled.div`
    @media only screen and (max-width: 481px) {
      display: none; 
    }
  `
  const H3Title = styled.h3`
    color: white;
    font-size: 40px;
    margin: 0;
  `
  const Banner = styled.div`
    display: block;
    height: 200px;
    background-position-y: 800px;
  `
  const Title = styled.div`
    height: auto;
    margin: 0 auto;
    display: flex;
    padding: 10px;
    position: relative;
    font-size: 25px;
    font-family: 'Oswald',sans-serif;
    text-align: center;
    width: 30%;
  `
  const Info = styled(Title)`
    display: block;
    width: 575px;
    padding-top: 10%;
  `
  
  const Footer = styled.footer`
    background-color: black;
    height: 220px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 50px;
  `

  const Sedes = styled.div`
    margin: 0 50px 0 50px;
    width: 450px
  `

  const Contacto = styled(Sedes)`
    margin: 0 50px 0 50px;
    width: 250px;
  `

  const UlSedes = styled.ul`
    padding: 0;
  `
  const H3Footer = styled(H3Title)`
    color: #0799d4;
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
  `

  const LiSedes = styled.li`
    color: white;
    font-family: 'Roboto', sans-serif;
    list-style: none;
    margin-bottom: 10px;
  `


  // Tabla de grupos

  const ContainerDivisional = styled.div`
    height: auto;
    margin: 0 auto;
    display: flex;
    padding: 30px;
    position: relative;
    font-size: 25px;
    font-family: 'Oswald',sans-serif;
    text-align: center;
    
    @media only screen and (min-width: 482px) {
      width: 90%;
    }
    @media only screen and (max-width: 481px) {
      width: 85%;
    }
  `

  const ContainerGrupos = styled.div`
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    position: relative;
    font-size: 25px;
    font-family: 'Oswald',sans-serif;
    text-align: center;
    width: 100%;

    @media only screen and (min-width: 482px) {
      padding: 0px 30px 30px 30px;
    }
    @media only screen and (max-width: 481px) {
      padding: 0;
    }
  `

  const Card = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin-bottom: 20px;
    &:hover{
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    @media only screen and (min-width: 482px) {
      width: 30%;
      margin-right: 20px;
    }
    @media only screen and (max-width: 481px) {
      width: 100%;
    }
  `
  const TituloGrupo = styled.div`
    height: 50px;
    width: 100%;
    font-weight: 700;
    background-color: black;
    color: white;
    padding: 0;
  `

  const BannerStatsGrupo = styled(TituloGrupo)`
    height: 30px;
    background-color: #525252;
    color: white;
    padding: 0;
  `

  const ColGrupo = styled(Col)`
    padding: 0;
    text-align: left;
    font-size: 15px;
  `

  const ColEquipo = styled(Col)`
    padding: 0;
    text-align: left;
    font-size: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

  const ColGrupoUnit = styled(ColGrupo)`
    text-align: center;
  `

  const RowGrupo = styled(Row)`
    padding: 0;
    margin: auto;
    width: 90%;
    font-weight: 500;
  `

  // Tabla de ultimos partidos
  const RowEquipo = styled(RowGrupo)`
    border-bottom: 1px solid #f1f1f1;
    justify-content: space-between;
  `

  const ButtonEquipo = styled.a`
    color: inherit;
    text-decoration: none;
  `

  // Mobile Viewport
  const FechasMobile = styled.div`
    @media only screen and (min-width: 482px) {
      display: none; 
    }
  `
  const SedesMobile = styled(Sedes)`
    margin: 0 10px;
    width: 30%;
  `
  const ContactoMobile = styled(Contacto)`
    margin: 0 10px;
    width: 30%;
  `

  const [tableFechas, setTableFechas] = useState();
  
  useEffect(() => {
    setTableFechas(fechas.map((fecha) => 
      <Card>
        <TituloGrupo>{fecha.nombre.toUpperCase()}</TituloGrupo>
        <div class="container">
          {fecha.partidos.map((partido) =>
            <ButtonEquipo href={"/partido/" + partido.idPartido + "/divisional/" + idDivisional}>
              <RowEquipo>
                <ColEquipo style={{width: "40%"}}>
                  {partido.equipo1.escudo == null ? <img style={{maxHeight: 15, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 15, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo1.escudo}/>}
                  {partido.equipo1.nombre}
                </ColEquipo>
                <ColEquipo style={{width: "20%", textAlign: "center"}}>
                  {partido.resultado.golesEquipo1 + " - " + partido.resultado.golesEquipo2}
                </ColEquipo>
                <ColEquipo style={{width: "40%", textAlign: "right"}}>
                  {partido.equipo2.nombre}
                  {partido.equipo2.escudo == null ? <img style={{maxHeight: 15, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 15, paddingLeft: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo2.escudo}/>}
                </ColEquipo>
              </RowEquipo>
            </ButtonEquipo>
          )},
        </div>
      </Card>
    ));
    urlStates.setIdDivisionalData(idDivisional);       
  }, [fechas]);

  return (
    <div className="main">
      <FechasDesktop>
        <Banner style={{backgroundImage: `url(${Background})`}}>
          <Title>
            <Info>
              <H3Title>FECHAS</H3Title>
            </Info>
          </Title>
        </Banner>

        <ContainerDivisional>
          <div style={{display: "flex", flexFlow: "column", width: "100%"}}>
            <ContainerGrupos> 
              {tableFechas}
            </ContainerGrupos>
          </div> 
        </ContainerDivisional>

        <Footer>
          <div style={{width: "350px"}}>
            <img src={`${LVHIcon}`} style={{maxWidth: 200, paddingTop: "2%"}}/>
          </div>
          <Sedes>
            <H3Footer>SEDES</H3Footer>
            <UlSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>El Galpón - Paraguay 2211 casi Freire - Uruguay</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Complejo Lokura Fútbol 7 - Camino Sosa Chaferro entre Luis Batlle Berres y Camino Las Flores Paso de la Arena</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Medio Campo - Avenida Dr. Luis Alberto de Herrera 2992</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Stokolmo - Camino Castro 404 bis</LiSedes>
            </UlSedes>
          </Sedes>
          <Contacto>
            <H3Footer>CONTACTO</H3Footer>
            <UlSedes>
              <LiSedes><img src={`${Email}`} style={{maxWidth: 20, paddingRight: "10px"}}/>lvheventos@gmail.com</LiSedes>
              <LiSedes><img src={`${Phone}`} style={{maxWidth: 20, paddingRight: "10px"}}/>+598 98 547 535</LiSedes>
            </UlSedes>
          </Contacto>
        </Footer>
      </FechasDesktop>
      
      <FechasMobile>
        <Banner style={{backgroundImage: `url(${Background})`}}>
          <Title>
            <Info>
              <H3Title>FECHAS</H3Title>
            </Info>
          </Title>
        </Banner>

        <ContainerDivisional>
          <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <ContainerGrupos> 
              {tableFechas}
            </ContainerGrupos>
          </div> 
        </ContainerDivisional>

        <Footer style={{paddingTop: "20px"}}>
          <div style={{width: "30%", margin: "0 10px"}}>
            <img src={`${LVHIcon}`} style={{maxWidth: 90, paddingTop: "2%"}}/>
          </div>
          <SedesMobile>
            <H3Footer>SEDES</H3Footer>
            <UlSedes style={{fontSize: "9px"}}>
              <LiSedes style={{marginBottom: "15px"}}><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>El Galpón - Paraguay 2211 casi Freire - Uruguay</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Complejo Lokura Fútbol 7 - Camino Sosa Chaferro entre Luis Batlle Berres y Camino Las Flores Paso de la Arena</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Medio Campo - Avenida Dr. Luis Alberto de Herrera 2992</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Stokolmo - Camino Castro 404 bis</LiSedes>
            </UlSedes>
          </SedesMobile>
          <ContactoMobile>
            <H3Footer>CONTACTO</H3Footer>
            <UlSedes style={{fontSize: "8px"}}>
              <LiSedes><img src={`${Email}`} style={{maxWidth: 10, paddingRight: "10px"}}/>lvheventos@gmail.com</LiSedes>
              <LiSedes><img src={`${Phone}`} style={{maxWidth: 10, paddingRight: "10px"}}/>+598 98 547 535</LiSedes>
            </UlSedes>
          </ContactoMobile>
        </Footer>
      </FechasMobile>
    </div>
  );
}

export default Fechas;

export async function loader({params}) {
  const axios = require('axios');
  let fechas = [];
  fechas.push(params.idDivisional);
  const config = {
      method: 'get',
      url: 'https://lvh.camila.uy/fecha/divisional/' + params.idDivisional,
      headers: {
        'Accept': 'application/json'
      }
  };

  await axios(config)
    .then(function (response) {
      fechas.push(response.data);
    })

  return fechas;
}