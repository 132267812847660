import Header from './components/Header';
import { Outlet } from "react-router-dom";
import { useState, useEffect, createContext } from 'react';

function App() {
  const [idDivisional, setIdDivisional] = useState("");

  const urlStates = {
    idDivisionalData: idDivisional,
    setIdDivisionalData: setIdDivisional,
  }

  return (
    <div className="main">
        <Header urlStates={urlStates}/>
        <div className="container">
          <Outlet context={urlStates}/>
        </div>
    </div>
  );
}

export default App;
