import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Inicio from './components/Inicio';
import Divisional, {loader as LoaderDivisional} from './components/divisional';
import Equipo, {loader as LoaderEquipo} from './components/equipo';
import Partido, {loader as LoaderPartido} from './components/partido';
import Jugador, {loader as LoaderJugador} from './components/Jugador';
import Estadisticas, {loader as LoaderEstadisticas} from './components/Estadisticas';
import FairPlay, {loader as LoaderFairPlay} from './components/FairPlay';
import Fechas, {loader as LoaderFechas} from './components/Fechas';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children: [
      {
        path: "",
        element: <Inicio />,
      },
      {
        path: "inicio",
        element: <Inicio />,
      },
      {
        path: "divisional/:divisional",
        loader: (props) => {
          return LoaderDivisional(props)},
        element: <Divisional/>
      },
      {
        path: "equipo/:equipoId/:idDivisional",
        loader: (props) => {
          return LoaderEquipo(props)},
        element: <Equipo />
      },
      {
        path: "partido/:idPartido/divisional/:idDivisional",
        loader: (props) => {
          return LoaderPartido(props)},
        element: <Partido />
      },
      {
        path: "jugador/:idJugador/divisional/:idDivisional",
        loader: (props) => {
          return LoaderJugador(props)},
        element: <Jugador />
      },
      {
        path: "estadisticas/:idDivisional",
        loader: (props) => {
          return LoaderEstadisticas(props)},
        element: <Estadisticas />
      },
      {
        path: "fairplay/:idDivisional",
        loader: (props) => {
          return LoaderFairPlay(props)},
        element: <FairPlay />
      },
      {
        path: "fechas/:idDivisional",
        loader: (props) => {
          return LoaderFechas(props)},
        element: <Fechas />
      }
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
