import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled, { isStyledComponent } from 'styled-components';
import { useState, useEffect } from 'react';
import Background from '../assets/img/stadium.jpg';
import LVHPlaceholder from '../assets/img/lvh_placeholder.png';
import LVHIcon from '../assets/img/lvh.png';
import Email from '../assets/img/email.png';
import Ball from '../assets/img/ball.png';
import Phone from '../assets/img/phone-call.png';
import { useOutletContext } from "react-router-dom";
import {
  Outlet,
  Link,
  useLoaderData,
} from "react-router-dom"
import { useParams } from 'react-router-dom';

function FairPlay() {
  const urlStates = useOutletContext();
  const [estadistica, setEstadistica] = useState(useLoaderData()[1]);
  const [mvps, setMVP] = useState(useLoaderData()[2]);
  const [suspendidos, setSuspendidos] = useState(useLoaderData()[3]);
  const idDivisional = useLoaderData()[0];
  console.log(estadistica);

  const FairPlayDesktop = styled.div`
    @media only screen and (max-width: 481px) {
      display: none; 
    }
  `

  const H3Title = styled.h3`
    color: white;
    font-size: 40px;
    margin: 0;
  `
  const Banner = styled.div`
    display: block;
    height: 200px;
    background-position-y: 800px;
  `
  const Title = styled.div`
    height: auto;
    margin: 0 auto;
    display: flex;
    padding: 10px;
    position: relative;
    font-size: 25px;
    font-family: 'Oswald',sans-serif;
    text-align: center;

    @media only screen and (min-width: 482px) {
      width: 30%;
    }
    @media only screen and (max-width: 481px) {
      width: 50%;
    }
  `
  const Info = styled(Title)`
    display: block;
    width: 575px;
    
    @media only screen and (min-width: 482px) {
      padding-top: 10%;
    }
    @media only screen and (max-width: 481px) {
      padding-top: 25%;
    }
  `
  
  const Footer = styled.footer`
    background-color: black;
    height: 220px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 50px;
  `

  const Sedes = styled.div`
    margin: 0 50px 0 50px;
    width: 450px
  `

  const Contacto = styled(Sedes)`
    margin: 0 50px 0 50px;
    width: 250px;
  `

  const UlSedes = styled.ul`
    padding: 0;
  `
  const H3Footer = styled(H3Title)`
    color: #0799d4;
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
  `

  const LiSedes = styled.li`
    color: white;
    font-family: 'Roboto', sans-serif;
    list-style: none;
    margin-bottom: 10px;
  `


  // Tabla de equipo
  const ContainerEquipo = styled.div`
    display: flex;
    justify-content: space-around;
    
    @media only screen and (min-width: 482px) {
      flex-direction: row;
    }
    @media only screen and (max-width: 481px) {
      flex-direction: column;
    }
  `

  const TablaEquipoPartidos = styled.div`
    display: flex;
    flew-flow: column;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    flex: 40%;
    height: 400px;
    margin: 20px;
    width: 100%;
    &:hover{
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
  `
  const RowJugador = styled(Row)`
    padding: 0;
    margin: auto;
    width: 90%;
    font-weight: 500;
    border-bottom: 1px solid #f1f1f1;
  `
  const ColEquipo = styled(Col)`
    padding: 0;
    text-align: left;
    font-size: 15px;
  `
  const ColEquipoUnit = styled(ColEquipo)`
    text-align: center;
  `

  const ColJugador = styled(Col)`
    padding: 0;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (min-width: 482px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 481px) {
      font-size: 15px;
    }
  `
  const ColJugadorUnit = styled(ColJugador)`
    margin-top: 5px;
    text-align: center;
  `

  const ButtonJugador = styled.a`
    color: inherit;
    text-decoration: none;
  `
  const TituloEquipo = styled.div`
    height: 50px;
    width: 100%;
    font-weight: 700;
    background-color: black;
    font-family: 'Oswald',sans-serif;
    font-size: 25px;
    text-align: center;
    color: white;
    padding: 0;
  `

  const RowEquipo = styled(Row)`
    padding: 0;
    margin: auto;
    width: 90%;
    font-weight: 500;
  `

  const TablaJugadores = styled(TablaEquipoPartidos)`
    width: 80%;
    flex-flow: column;
  `

  const BannerStatsEquipo = styled(TituloEquipo)`
    height: 30px;
    background-color: #525252;
    color: white;
    padding: 0;
  `

  // Tabla de ultimos partidos
  const H3TitleUltPartidos = styled(H3Title)`
    color: black;
    font-family: 'Oswald',sans-serif;
    font-size: 40px;
    font-weight: 500;
    text-align: center;

    @media only screen and (min-width: 482px) {
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 481px) {
      margin-bottom: 0px;
    }
  `

  // Mobile Viewport
  const FairPlayMobile = styled.div`
    @media only screen and (min-width: 482px) {
      display: none; 
    }
  `
  const SedesMobile = styled(Sedes)`
    margin: 0 10px;
    width: 30%;
  `
  const ContactoMobile = styled(Contacto)`
    margin: 0 10px;
    width: 30%;
  `

  const [tableAmarillas, setTableAmarillas] = useState();

  const [tableRojas, setTableRojas] = useState();

  const [tableMVP, setMVPs] = useState();

  const [tableSuspendidos, setTableSuspendidos] = useState();

  
  useEffect(() => {
    setTableAmarillas(estadistica.amarillas.slice(0, 10).map((jugador) => 
      <ButtonJugador href={"/jugador/" + jugador.idJugador + "/divisional/" + idDivisional}>
        <RowJugador>
          <ColJugador style={{width: "10%"}}>{estadistica.amarillas.indexOf(jugador) + 1}</ColJugador>
          <ColJugador style={{width: "70%"}}>{jugador.nombre + " " + jugador.apellido}</ColJugador>
          <ColJugadorUnit style={{width: "10%"}}>{jugador.tarjetas}</ColJugadorUnit>
        </RowJugador>
      </ButtonJugador>
    ));

    setTableRojas(estadistica.rojas.slice(0, 10).map((jugador) => 
      <ButtonJugador href={"/jugador/" + jugador.idJugador + "/divisional/" + idDivisional}>
        <RowJugador>
          <ColJugador style={{width: "10%"}}>{estadistica.rojas.indexOf(jugador) + 1}</ColJugador>
          <ColJugador style={{width: "70%"}}>{jugador.nombre + " " + jugador.apellido}</ColJugador>
          <ColJugadorUnit style={{width: "10%"}}>{jugador.tarjetas}</ColJugadorUnit>
        </RowJugador>
      </ButtonJugador>
    ));

    setMVPs(mvps.slice(0, 10).map((jugador) => 
      <ButtonJugador href={"/jugador/" + jugador.idJugador + "/divisional/" + idDivisional}>
        <RowJugador>
          <ColJugador style={{width: "10%"}}>{mvps.indexOf(jugador) + 1}</ColJugador>
          <ColJugador style={{width: "40%"}}>{jugador.nombre + " " + jugador.apellido}</ColJugador>
          <ColJugador style={{width: "40%"}}>
            {jugador.escudo == null ? <img style={{maxHeight: 15, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 15, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + jugador.escudo}/>}
            {jugador.nombre_equipo}
          </ColJugador>
          <ColJugadorUnit style={{width: "10%"}}>{jugador.partidos}</ColJugadorUnit>
        </RowJugador>
      </ButtonJugador>
    ));

    setTableSuspendidos(suspendidos.slice(0, 10).map((jugador) => 
      <ButtonJugador href={"/jugador/" + jugador.idJugador + "/divisional/" + idDivisional}>
        <RowJugador>
          <ColJugador style={{width: "10%"}}>{suspendidos.indexOf(jugador) + 1}</ColJugador>
          <ColJugador style={{width: "40%"}}>{jugador.nombre + " " + jugador.apellido}</ColJugador>
          <ColJugador style={{width: "40%"}}>
            {jugador.escudo == null ? <img style={{maxHeight: 15, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 15, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + jugador.escudo}/>}
            {jugador.nombre_equipo}
          </ColJugador>
          <ColJugadorUnit style={{width: "10%"}}>{jugador.partidosPendientes}</ColJugadorUnit>
        </RowJugador>
      </ButtonJugador>
    ));
    urlStates.setIdDivisionalData(idDivisional);   
  }, [estadistica]);

  return (
    <div className="main">
      <FairPlayDesktop>
        <Banner style={{backgroundImage: `url(${Background})`}}>
          <Title>
            <Info>
              <H3Title>FAIR PLAY</H3Title>
            </Info>
          </Title>
        </Banner>

        <ContainerEquipo>
          <div style={{display: "flex", flexFlow: "column", width: "25%", alignItems: "center",}}>
            <H3TitleUltPartidos>AMARILLAS</H3TitleUltPartidos>
            <TablaJugadores>
                <TituloEquipo>JUGADORES</TituloEquipo>
                <BannerStatsEquipo>
                  <RowEquipo>
                    <ColEquipo style={{width: "10%"}}>#</ColEquipo>
                    <ColEquipo style={{width: "70%"}}>Jugador</ColEquipo>
                    <ColEquipoUnit style={{width: "10%"}}>CANT</ColEquipoUnit>
                  </RowEquipo>
                </BannerStatsEquipo>
                {tableAmarillas}
            </TablaJugadores>
          </div>

          <div style={{display: "flex", flexFlow: "column", width: "25%", alignItems: "center",}}>
            <H3TitleUltPartidos>ROJAS</H3TitleUltPartidos>
            <TablaJugadores>
                <TituloEquipo>JUGADORES</TituloEquipo>
                <BannerStatsEquipo>
                  <RowEquipo>
                    <ColEquipo style={{width: "10%"}}>#</ColEquipo>
                    <ColEquipo style={{width: "70%"}}>Jugador</ColEquipo>
                    <ColEquipoUnit style={{width: "10%"}}>Cant</ColEquipoUnit>
                  </RowEquipo>
                </BannerStatsEquipo>
                {tableRojas}
            </TablaJugadores>
          </div>

          <div style={{display: "flex", flexFlow: "column", width: "25%", alignItems: "center",}}>
            <H3TitleUltPartidos>FIGURAS</H3TitleUltPartidos>
            <TablaJugadores>
                <TituloEquipo>JUGADORES</TituloEquipo>
                <BannerStatsEquipo>
                  <RowEquipo>
                    <ColEquipo style={{width: "10%"}}>#</ColEquipo>
                    <ColEquipo style={{width: "40%"}}>Jugador</ColEquipo>
                    <ColEquipo style={{width: "40%"}}>Equipo</ColEquipo>
                    <ColEquipoUnit style={{width: "10%"}}>Cant.</ColEquipoUnit>
                  </RowEquipo>
                </BannerStatsEquipo>
                {tableMVP}
            </TablaJugadores>
          </div>

          <div style={{display: "flex", flexFlow: "column", width: "25%", alignItems: "center",}}>
            <H3TitleUltPartidos>SUSPENDIDOS</H3TitleUltPartidos>
            <TablaJugadores>
                <TituloEquipo>JUGADORES</TituloEquipo>
                <BannerStatsEquipo>
                  <RowEquipo>
                    <ColEquipo style={{width: "10%"}}>#</ColEquipo>
                    <ColEquipo style={{width: "40%"}}>Jugador</ColEquipo>
                    <ColEquipo style={{width: "40%"}}>Equipo</ColEquipo>
                    <ColEquipoUnit style={{width: "10%"}}>Cant.</ColEquipoUnit>
                  </RowEquipo>
                </BannerStatsEquipo>
                {tableSuspendidos}
            </TablaJugadores>
          </div>
        </ContainerEquipo>

        <Footer>
          <div style={{width: "350px"}}>
            <img src={`${LVHIcon}`} style={{maxWidth: 200, paddingTop: "2%"}}/>
          </div>
          <Sedes>
            <H3Footer>SEDES</H3Footer>
            <UlSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>El Galpón - Paraguay 2211 casi Freire - Uruguay</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Complejo Lokura Fútbol 7 - Camino Sosa Chaferro entre Luis Batlle Berres y Camino Las Flores Paso de la Arena</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Medio Campo - Avenida Dr. Luis Alberto de Herrera 2992</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Stokolmo - Camino Castro 404 bis</LiSedes>
            </UlSedes>
          </Sedes>
          <Contacto>
            <H3Footer>CONTACTO</H3Footer>
            <UlSedes>
              <LiSedes><img src={`${Email}`} style={{maxWidth: 20, paddingRight: "10px"}}/>lvheventos@gmail.com</LiSedes>
              <LiSedes><img src={`${Phone}`} style={{maxWidth: 20, paddingRight: "10px"}}/>+598 98 547 535</LiSedes>
            </UlSedes>
          </Contacto>
        </Footer>
      </FairPlayDesktop>
      
      <FairPlayMobile>
        <Banner style={{backgroundImage: `url(${Background})`}}>
            <Title>
              <Info>
                <H3Title>FAIR PLAY</H3Title>
              </Info>
            </Title>
          </Banner>

          <ContainerEquipo>
            <div style={{display: "flex", flexFlow: "column", width: "100%", alignItems: "center",}}>
              <H3TitleUltPartidos>AMARILLAS</H3TitleUltPartidos>
              <TablaJugadores>
                  <TituloEquipo>JUGADORES</TituloEquipo>
                  <BannerStatsEquipo>
                    <RowEquipo>
                      <ColEquipo style={{width: "10%"}}>#</ColEquipo>
                      <ColEquipo style={{width: "70%"}}>Jugador</ColEquipo>
                      <ColEquipoUnit style={{width: "10%"}}>CANT</ColEquipoUnit>
                    </RowEquipo>
                  </BannerStatsEquipo>
                  {tableAmarillas}
              </TablaJugadores>
            </div>

            <div style={{display: "flex", flexFlow: "column", width: "100%", alignItems: "center",}}>
              <H3TitleUltPartidos>ROJAS</H3TitleUltPartidos>
              <TablaJugadores>
                  <TituloEquipo>JUGADORES</TituloEquipo>
                  <BannerStatsEquipo>
                    <RowEquipo>
                      <ColEquipo style={{width: "10%"}}>#</ColEquipo>
                      <ColEquipo style={{width: "70%"}}>Jugador</ColEquipo>
                      <ColEquipoUnit style={{width: "10%"}}>Cant</ColEquipoUnit>
                    </RowEquipo>
                  </BannerStatsEquipo>
                  {tableRojas}
              </TablaJugadores>
            </div>

            <div style={{display: "flex", flexFlow: "column", width: "100%", alignItems: "center",}}>
              <H3TitleUltPartidos>FIGURAS</H3TitleUltPartidos>
              <TablaJugadores>
                  <TituloEquipo>JUGADORES</TituloEquipo>
                  <BannerStatsEquipo>
                    <RowEquipo>
                      <ColEquipo style={{width: "10%"}}>#</ColEquipo>
                      <ColEquipo style={{width: "40%"}}>Jugador</ColEquipo>
                      <ColEquipo style={{width: "40%"}}>Equipo</ColEquipo>
                      <ColEquipoUnit style={{width: "10%"}}>Cant.</ColEquipoUnit>
                    </RowEquipo>
                  </BannerStatsEquipo>
                  {tableMVP}
              </TablaJugadores>
            </div>

            <div style={{display: "flex", flexFlow: "column", width: "100%", alignItems: "center",}}>
              <H3TitleUltPartidos>SUSPENDIDOS</H3TitleUltPartidos>
              <TablaJugadores>
                  <TituloEquipo>JUGADORES</TituloEquipo>
                  <BannerStatsEquipo>
                    <RowEquipo>
                      <ColEquipo style={{width: "10%"}}>#</ColEquipo>
                      <ColEquipo style={{width: "40%"}}>Jugador</ColEquipo>
                      <ColEquipo style={{width: "40%"}}>Equipo</ColEquipo>
                      <ColEquipoUnit style={{width: "10%"}}>Cant.</ColEquipoUnit>
                    </RowEquipo>
                  </BannerStatsEquipo>
                  {tableSuspendidos}
              </TablaJugadores>
            </div>
          </ContainerEquipo>

          <Footer style={{paddingTop: "20px"}}>
            <div style={{width: "30%", margin: "0 10px"}}>
              <img src={`${LVHIcon}`} style={{maxWidth: 90, paddingTop: "2%"}}/>
            </div>
            <SedesMobile>
              <H3Footer>SEDES</H3Footer>
              <UlSedes style={{fontSize: "9px"}}>
                <LiSedes style={{marginBottom: "15px"}}><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>El Galpón - Paraguay 2211 casi Freire - Uruguay</LiSedes>
                <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Complejo Lokura Fútbol 7 - Camino Sosa Chaferro entre Luis Batlle Berres y Camino Las Flores Paso de la Arena</LiSedes>
                <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Medio Campo - Avenida Dr. Luis Alberto de Herrera 2992</LiSedes>
                <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Stokolmo - Camino Castro 404 bis</LiSedes>
              </UlSedes>
            </SedesMobile>
            <ContactoMobile>
              <H3Footer>CONTACTO</H3Footer>
              <UlSedes style={{fontSize: "8px"}}>
                <LiSedes><img src={`${Email}`} style={{maxWidth: 10, paddingRight: "10px"}}/>lvheventos@gmail.com</LiSedes>
                <LiSedes><img src={`${Phone}`} style={{maxWidth: 10, paddingRight: "10px"}}/>+598 98 547 535</LiSedes>
              </UlSedes>
            </ContactoMobile>
          </Footer>
        </FairPlayMobile>
    </div>
  );
}

export default FairPlay;

export async function loader({params}) {
  console.log(params);
  const axios = require('axios');
  let estadisticas = [];
  estadisticas.push(params.idDivisional);

  const configTarjetas = {
    method: 'get',
    url: 'https://lvh.camila.uy/divisional/' + params.idDivisional + '/tarjetas',
    headers: {
      'Accept': 'application/json'
    }
  };

  const configMVP = {
    method: 'get',
    url: 'https://lvh.camila.uy/divisional/' + params.idDivisional + '/mvp',
    headers: {
      'Accept': 'application/json'
    }
  };

  const configSuspendidos = {
    method: 'get',
    url: 'https://lvh.camila.uy/divisional/' + params.idDivisional + '/suspendidos',
    headers: {
      'Accept': 'application/json'
    }
  };

  await axios(configTarjetas)
    .then(function (response) {
      estadisticas.push(response.data);
    });

  await axios(configMVP)
    .then(function (response) {
      estadisticas.push(response.data);
    });

  await axios(configSuspendidos)
    .then(function (response) {
      estadisticas.push(response.data);
    });

  return estadisticas;
}