import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled, { isStyledComponent } from 'styled-components';
import { useState, useEffect } from 'react';
import Background from '../assets/img/stadium.jpg';
import LVHPlaceholder from '../assets/img/lvh_placeholder.png';
import LVHIcon from '../assets/img/lvh.png';
import Email from '../assets/img/email.png';
import Ball from '../assets/img/ball.png';
import BlackBall from '../assets/img/ball-black.png';
import Phone from '../assets/img/phone-call.png';
import YellowCard from '../assets/img/yellow_card.png';
import RedCard from '../assets/img/red_card.png';
import { useOutletContext } from "react-router-dom";
import {
  Outlet,
  Link,
  useLoaderData,
} from "react-router-dom"
import { useParams } from 'react-router-dom';

function Partido() {
  const urlStates = useOutletContext();
  const [partido, setPartido] = useState(useLoaderData()[1]);
  const idDivisional = useLoaderData()[0];
  
  const PartidoDesktop = styled.div`
    @media only screen and (max-width: 481px) {
      display: none; 
    }
  `
  const H3Title = styled.h3`
    color: white;
    font-size: 40px;
    margin: 0;
  `
  const Banner = styled.div`
    display: block;
    height: 200px;
    background-position-y: 800px;
  `
  const Title = styled.div`
    height: auto;
    margin: 0 auto;
    display: flex;
    padding: 10px;
    position: relative;
    font-size: 25px;
    font-family: 'Oswald',sans-serif;
    text-align: center;
    width: 50%;
  `
  const Info = styled(Title)`
    display: block;
    width: 575px;
    @media only screen and (min-width: 482px) {
      padding-top: 6%;
    }
    @media only screen and (max-width: 481px) {
      padding-top: 10%;
    }
  `
  
  const Footer = styled.footer`
    background-color: black;
    height: 220px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 50px;
  `

  const Sedes = styled.div`
    margin: 0 50px 0 50px;
    width: 450px
  `

  const Contacto = styled(Sedes)`
    margin: 0 50px 0 50px;
    width: 250px;
  `

  const UlSedes = styled.ul`
    padding: 0;
  `
  const H3Footer = styled(H3Title)`
    color: #0799d4;
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
  `

  const LiSedes = styled.li`
    color: white;
    font-family: 'Roboto', sans-serif;
    list-style: none;
    margin-bottom: 10px;
  `


  // Tabla de Partido
  const ContainerPartido = styled.div`
    display: flex;
    justify-content: center;
    @media only screen and (min-width: 482px) {
      flex-direction: row;
    }
    @media only screen and (max-width: 481px) {
      flex-direction: column;
      align-items: center;
    }
  `

  const CardPartido = styled.div`
    display: flex;
    flew-flow: column;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    flex: 50%;
    margin-left: 0 !important;
    height: 400px;
    margin: 20px;
    width: 100%;
    &:hover{
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
  `
  const InfoEquipo = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 45%;
    @media only screen and (min-width: 482px) {
      padding: 35px;
    }
    @media only screen and (max-width: 481px) {
      padding: 10px;
    }
  `

  const H3Partido = styled(H3Title)`
    color: black;
    font-family: 'Oswald',sans-serif;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    @media only screen and (min-width: 482px) {
      font-size: 40px;
    }
    @media only screen and (max-width: 481px) {
      font-size: 35px;
    }
  `

  const PEquipo = styled.p`
    font-family: 'Oswald',sans-serif;
    @media only screen and (min-width: 482px) {
      font-size: 35px;
    }
    @media only screen and (max-width: 481px) {
      font-size: 25px;
      text-align: center;
    }
  `

  const Resultado = styled.div`
    font-size: 40px;
    width: 20%;
    font-weight: 700;
    @media only screen and (min-width: 482px) {
      font-size: 40px;
    }
    @media only screen and (max-width: 481px) {
      font-size: 20px;
    }
  `
  
  // Resumen del Partido
  const BannerEquipoResumen = styled.div`
    display: flex;
    padding-top: 20px;
    justify-content: center;
    flex-flow; row;
  `
  const EquipoResumen = styled.div`
    width: 50%;
    border-right: 1px solid #black;
  `
  const StatsEquipoPartido = styled.div`
    @media only screen and (min-width: 482px) {
      padding: 10px 40px;
    }
    @media only screen and (max-width: 481px) {
      padding: 10px 15px;
    }
  `

  // Jueces bla bla
  const CardJuecesLugar = styled(CardPartido)`
    height: 100px;
    margin-top: 0px;
    margin-left: 0px;
  `

  const PJuecesLugar = styled.p`
    font-family: 'Oswald',sans-serif;
    @media only screen and (min-width: 482px) {
      font-size: 25px;
    }
    @media only screen and (max-width: 481px) {
      font-size: 20px;
      text-align: center;
    }
  `

  const H3JuezLugar = styled(H3Partido)`
    font-size: 28px;
    margin-top: 0;
  `

  // Mobile viewport
  const PartidoMobile = styled.div`
    @media only screen and (min-width: 482px) {
      display: none; 
    }
  `
  const SedesMobile = styled(Sedes)`
    margin: 0 10px;
    width: 30%;
  `
  const ContactoMobile = styled(Contacto)`
    margin: 0 10px;
    width: 30%;
  `

  const [golesEquipo1, setGolesEquipo1] = useState();

  const [tarjetasEquipo1, setTarjetasEquipo1] = useState(<p></p>);

  const [golesEquipo2, setGolesEquipo2] = useState();

  const [tarjetasEquipo2, setTarjetasEquipo2] = useState(<p></p>);


  const [golesEquipo1Mobile, setGolesEquipo1Mobile] = useState();

  const [tarjetasEquipo1Mobile, setTarjetasEquipo1Mobile] = useState(<p></p>);

  const [golesEquipo2Mobile, setGolesEquipo2Mobile] = useState();

  const [tarjetasEquipo2Mobile, setTarjetasEquipo2Mobile] = useState(<p></p>);

  // Desktop
  useEffect(() => {
    setGolesEquipo1(partido.goles.map((gol) =>
      gol.idEquipoG == partido.equipo1.idEquipo ? <p style={{fontSize: "18px"}}><img src={`${BlackBall}`} style={{maxWidth: 15, paddingRight: "10px"}}/>{gol.nombre + " " + gol.apellido}</p> : null
    ));

    setTarjetasEquipo1(partido.tarjetas.map((tarjeta) =>
      tarjeta.idEquipo == partido.equipo1.idEquipo ? tarjeta.color == 0 ? <p style={{fontSize: "18px"}}><img src={`${YellowCard}`} style={{maxWidth: 15, paddingRight: "10px"}}/>{tarjeta.nombre + " " + tarjeta.apellido}</p> 
      :
      <p style={{fontSize: "18px"}}><img src={`${RedCard}`} style={{maxWidth: 15, paddingRight: "10px"}}/>{tarjeta.nombre + " " + tarjeta.apellido}</p> : null
    ));

    setGolesEquipo2(partido.goles.map((gol) =>
      gol.idEquipoG == partido.equipo2.idEquipo ? <p style={{fontSize: "18px"}}><img src={`${BlackBall}`} style={{maxWidth: 15, paddingRight: "10px"}}/>{gol.nombre + " " + gol.apellido}</p> : null
    ));

    setTarjetasEquipo2(partido.tarjetas.map((tarjeta) =>
      tarjeta.idEquipo == partido.equipo2.idEquipo ? tarjeta.color == 0 ? <p style={{fontSize: "18px"}}><img src={`${YellowCard}`} style={{maxWidth: 15, paddingRight: "10px"}}/>{tarjeta.nombre + " " + tarjeta.apellido}</p> 
      :
      <p style={{fontSize: "18px"}}><img src={`${RedCard}`} style={{maxWidth: 15, paddingRight: "10px"}}/>{tarjeta.nombre + " " + tarjeta.apellido}</p> : null
    ));
    
    urlStates.setIdDivisionalData(idDivisional);
  }, [partido]);

  // Mobile
  useEffect(() => {
    setGolesEquipo1Mobile(partido.goles.map((gol) =>
      gol.idEquipoG == partido.equipo1.idEquipo ? <p style={{fontSize: "14px"}}><img src={`${BlackBall}`} style={{maxWidth: 12, paddingRight: "10px"}}/>{gol.nombre + " " + gol.apellido}</p> : null
    ));

    setTarjetasEquipo1Mobile(partido.tarjetas.map((tarjeta) =>
      tarjeta.idEquipo == partido.equipo1.idEquipo ? tarjeta.color == 0 ? <p style={{fontSize: "14px"}}><img src={`${YellowCard}`} style={{maxWidth: 12, paddingRight: "10px"}}/>{tarjeta.nombre + " " + tarjeta.apellido}</p> 
      :
      <p style={{fontSize: "14px"}}><img src={`${RedCard}`} style={{maxWidth: 12, paddingRight: "10px"}}/>{tarjeta.nombre + " " + tarjeta.apellido}</p> : null
    ));

    setGolesEquipo2Mobile(partido.goles.map((gol) =>
      gol.idEquipoG == partido.equipo2.idEquipo ? <p style={{fontSize: "14px"}}><img src={`${BlackBall}`} style={{maxWidth: 12, paddingRight: "10px"}}/>{gol.nombre + " " + gol.apellido}</p> : null
    ));

    setTarjetasEquipo2Mobile(partido.tarjetas.map((tarjeta) =>
      tarjeta.idEquipo == partido.equipo2.idEquipo ? tarjeta.color == 0 ? <p style={{fontSize: "14px"}}><img src={`${YellowCard}`} style={{maxWidth: 12, paddingRight: "10px"}}/>{tarjeta.nombre + " " + tarjeta.apellido}</p> 
      :
      <p style={{fontSize: "14px"}}><img src={`${RedCard}`} style={{maxWidth: 12, paddingRight: "10px"}}/>{tarjeta.nombre + " " + tarjeta.apellido}</p> : null
    ));
    
    urlStates.setIdDivisionalData(idDivisional);
  }, [partido]);

  return (
    <div className="main">
      <PartidoDesktop>
        <Banner style={{backgroundImage: `url(${Background})`}}>
          <Title>
            <Info>
              <H3Title>RESULTADO DEL PARTIDO</H3Title>
            </Info>
          </Title>
        </Banner>

        <ContainerPartido>
          <div style={{display: "flex", flexFlow: "column", width: "40%"}}>
            <H3Partido>RESULTADO FINAL</H3Partido>
            <CardPartido>

              <InfoEquipo>
                {partido.equipo1.escudo == null ? <img style={{maxHeight: 250, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 250, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo1.escudo}/>}
                <PEquipo>{partido.equipo1.nombre}</PEquipo>
              </InfoEquipo>
              
              <Resultado>
                <p style={{position: "relative", top: "25%"}}>{partido.resultado.golesEquipo1 + " - " + partido.resultado.golesEquipo2}</p>
              </Resultado>

              <InfoEquipo>
                {partido.equipo2.escudo == null ? <img style={{maxHeight: 250, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 250, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo2.escudo}/>}
                <PEquipo>{partido.equipo2.nombre}</PEquipo>
              </InfoEquipo>

            </CardPartido>
            <div style={{display: "flex", flexFlow: "row", width: "100%", justifyContent: "center"}}>
              <div style={{display: "flex", flexFlow: "column", width: "100%", justifyContent: "center", margin: "10px"}}>
                <H3JuezLugar>JUEZ</H3JuezLugar>
                <CardJuecesLugar>
                  {partido.juez != null ? <PJuecesLugar>{partido.juez.nombre}</PJuecesLugar> : <PJuecesLugar>No especificado</PJuecesLugar>}
                </CardJuecesLugar>
              </div>
              <div style={{display: "flex", flexFlow: "column", width: "100%", justifyContent: "center", margin: "10px"}}>
                <H3JuezLugar>LUGAR</H3JuezLugar>
                <CardJuecesLugar>
                  {partido.lugar != null ? <PJuecesLugar>{partido.lugar.nombre}</PJuecesLugar> : <PJuecesLugar>No especificado</PJuecesLugar>}
                </CardJuecesLugar>
              </div>
              <div style={{display: "flex", flexFlow: "column", width: "100%", justifyContent: "center", margin: "10px"}}>
                <H3JuezLugar>ESTADO</H3JuezLugar>
                <CardJuecesLugar>
                  <PJuecesLugar>{partido.statusFN}</PJuecesLugar>
                </CardJuecesLugar>
              </div>
            </div>
          </div>

          <div style={{display: "flex", flexFlow: "column", width: "40%", marginLeft: "5%"}}>
            <H3Partido>RESUMEN DEL PARTIDO</H3Partido>
            <CardPartido>
              <div style={{display: "flex", flexFlow: "row", width: "100%"}}>
                <EquipoResumen style={{borderRight: "3px solid #f1f1f1"}}>
                  <BannerEquipoResumen>
                    {partido.equipo1.escudo == null ? <img style={{maxHeight: 50, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 50, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo1.escudo}/>}
                    <PEquipo style={{fontSize: "15px"}}>{partido.equipo1.nombre}</PEquipo>
                  </BannerEquipoResumen>
                  <StatsEquipoPartido>
                    {golesEquipo1}
                    {tarjetasEquipo1}
                  </StatsEquipoPartido>
                </EquipoResumen>
                <EquipoResumen>
                  <BannerEquipoResumen>
                    {partido.equipo2.escudo == null ? <img style={{maxHeight: 50, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 50, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo2.escudo}/>}
                    <PEquipo style={{fontSize: "15px"}}>{partido.equipo2.nombre}</PEquipo>
                  </BannerEquipoResumen>
                  <StatsEquipoPartido>
                    {golesEquipo2}
                    {tarjetasEquipo2}
                  </StatsEquipoPartido>
                </EquipoResumen>
              </div>
            </CardPartido>
          </div>
        </ContainerPartido>

        <Footer>
          <div style={{width: "350px"}}>
            <img src={`${LVHIcon}`} style={{maxWidth: 200, paddingTop: "2%"}}/>
          </div>
          <Sedes>
            <H3Footer>SEDES</H3Footer>
            <UlSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>El Galpón - Paraguay 2211 casi Freire - Uruguay</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Complejo Lokura Fútbol 7 - Camino Sosa Chaferro entre Luis Batlle Berres y Camino Las Flores Paso de la Arena</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Medio Campo - Avenida Dr. Luis Alberto de Herrera 2992</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Stokolmo - Camino Castro 404 bis</LiSedes>
            </UlSedes>
          </Sedes>
          <Contacto>
            <H3Footer>CONTACTO</H3Footer>
            <UlSedes>
              <LiSedes><img src={`${Email}`} style={{maxWidth: 20, paddingRight: "10px"}}/>lvheventos@gmail.com</LiSedes>
              <LiSedes><img src={`${Phone}`} style={{maxWidth: 20, paddingRight: "10px"}}/>+598 98 547 535</LiSedes>
            </UlSedes>
          </Contacto>
        </Footer>
      </PartidoDesktop>

      <PartidoMobile>
        <Banner style={{backgroundImage: `url(${Background})`}}>
          <Title>
            <Info>
              <H3Title>RESULTADO DEL PARTIDO</H3Title>
            </Info>
          </Title>
        </Banner>

        <ContainerPartido>
          <div style={{display: "flex", flexFlow: "column", width: "90%"}}>
            <H3Partido>RESULTADO FINAL</H3Partido>
            <CardPartido>

              <InfoEquipo>
                {partido.equipo1.escudo == null ? <img style={{maxHeight: 110, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 110, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo1.escudo}/>}
                <PEquipo>{partido.equipo1.nombre}</PEquipo>
              </InfoEquipo>
              
              <Resultado>
                <p style={{position: "relative", top: "25%"}}>{partido.resultado.golesEquipo1 + " - " + partido.resultado.golesEquipo2}</p>
              </Resultado>

              <InfoEquipo>
                {partido.equipo2.escudo == null ? <img style={{maxHeight: 110, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 110, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo2.escudo}/>}
                <PEquipo>{partido.equipo2.nombre}</PEquipo>
              </InfoEquipo>

            </CardPartido>
            <div style={{display: "flex", flexFlow: "row", width: "100%", justifyContent: "center"}}>
              <div style={{display: "flex", flexFlow: "column", width: "100%", justifyContent: "center", margin: "10px"}}>
                <H3JuezLugar>JUEZ</H3JuezLugar>
                <CardJuecesLugar>
                  {partido.juez != null ? <PJuecesLugar>{partido.juez.nombre}</PJuecesLugar> : <PJuecesLugar>No especificado</PJuecesLugar>}
                </CardJuecesLugar>
              </div>
              <div style={{display: "flex", flexFlow: "column", width: "100%", justifyContent: "center", margin: "10px"}}>
                <H3JuezLugar>LUGAR</H3JuezLugar>
                <CardJuecesLugar>
                  {partido.lugar != null ? <PJuecesLugar>{partido.lugar.nombre}</PJuecesLugar> : <PJuecesLugar>No especificado</PJuecesLugar>}
                </CardJuecesLugar>
              </div>
              <div style={{display: "flex", flexFlow: "column", width: "100%", justifyContent: "center", margin: "10px"}}>
                <H3JuezLugar>ESTADO</H3JuezLugar>
                <CardJuecesLugar>
                  <PJuecesLugar>{partido.statusFN}</PJuecesLugar>
                </CardJuecesLugar>
              </div>
            </div>
          </div>

          <div style={{display: "flex", flexFlow: "column", width: "90%"}}>
            <H3Partido>RESUMEN DEL PARTIDO</H3Partido>
            <CardPartido>
              <div style={{display: "flex", flexFlow: "row", width: "100%"}}>
                <EquipoResumen style={{borderRight: "3px solid #f1f1f1"}}>
                  <BannerEquipoResumen>
                    {partido.equipo1.escudo == null ? <img style={{maxHeight: 50, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 50, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo1.escudo}/>}
                    <PEquipo style={{fontSize: "15px"}}>{partido.equipo1.nombre}</PEquipo>
                  </BannerEquipoResumen>
                  <StatsEquipoPartido>
                    {golesEquipo1Mobile}
                    {tarjetasEquipo1Mobile}
                  </StatsEquipoPartido>
                </EquipoResumen>
                <EquipoResumen>
                  <BannerEquipoResumen>
                    {partido.equipo2.escudo == null ? <img style={{maxHeight: 50, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 50, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo2.escudo}/>}
                    <PEquipo style={{fontSize: "15px"}}>{partido.equipo2.nombre}</PEquipo>
                  </BannerEquipoResumen>
                  <StatsEquipoPartido>
                    {golesEquipo2Mobile}
                    {tarjetasEquipo2Mobile}
                  </StatsEquipoPartido>
                </EquipoResumen>
              </div>
            </CardPartido>
          </div>
        </ContainerPartido>

        <Footer style={{paddingTop: "20px"}}>
          <div style={{width: "30%", margin: "0 10px"}}>
            <img src={`${LVHIcon}`} style={{maxWidth: 90, paddingTop: "2%"}}/>
          </div>
          <SedesMobile>
            <H3Footer>SEDES</H3Footer>
            <UlSedes style={{fontSize: "9px"}}>
              <LiSedes style={{marginBottom: "15px"}}><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>El Galpón - Paraguay 2211 casi Freire - Uruguay</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Complejo Lokura Fútbol 7 - Camino Sosa Chaferro entre Luis Batlle Berres y Camino Las Flores Paso de la Arena</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Medio Campo - Avenida Dr. Luis Alberto de Herrera 2992</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Stokolmo - Camino Castro 404 bis</LiSedes>
            </UlSedes>
          </SedesMobile>
          <ContactoMobile>
            <H3Footer>CONTACTO</H3Footer>
            <UlSedes style={{fontSize: "8px"}}>
              <LiSedes><img src={`${Email}`} style={{maxWidth: 10, paddingRight: "10px"}}/>lvheventos@gmail.com</LiSedes>
              <LiSedes><img src={`${Phone}`} style={{maxWidth: 10, paddingRight: "10px"}}/>+598 98 547 535</LiSedes>
            </UlSedes>
          </ContactoMobile>
        </Footer>
      </PartidoMobile>
    </div>
  );
}

export default Partido;

export async function loader({params}) {
  console.log(params);
  const axios = require('axios');
  let partido = [];
  partido.push(params.idDivisional);
  const config = {
      method: 'get',
      url: 'https://lvh.camila.uy/partido/' + params.idPartido, 
      headers: {
        'Accept': 'application/json'
      }
  };

  await axios(config)
    .then(function (response) {
      partido.push(response.data);
    })

  return partido;
}