import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled, { isStyledComponent } from 'styled-components';
import { useState, useEffect } from 'react';
import Background from '../assets/img/stadium.jpg';
import LVHPlaceholder from '../assets/img/lvh_placeholder.png';
import LVHIcon from '../assets/img/lvh.png';
import Email from '../assets/img/email.png';
import Ball from '../assets/img/ball.png';
import Phone from '../assets/img/phone-call.png';
import Player from '../assets/img/player.png';
import { useOutletContext } from "react-router-dom";
import {
  Outlet,
  Link,
  useLoaderData,
} from "react-router-dom"
import { useParams } from 'react-router-dom';

function Jugador() {
  const urlStates = useOutletContext();
  const [jugador, setJugador] = useState(useLoaderData()[1]);
  const idDivisional =useLoaderData()[0];
  console.log(jugador);

  const JugadorDesktop = styled.div`
    @media only screen and (max-width: 481px) {
      display: none; 
    }
  `

  const H3Title = styled.h3`
    color: white;
    font-size: 40px;
    margin: 0;
  `
  const Banner = styled.div`
    display: block;
    height: 200px;
    background-position-y: 800px;
  `
  const Title = styled.div`
    height: auto;
    margin: 0 auto;
    display: flex;
    padding: 10px;
    position: relative;
    font-size: 25px;
    font-family: 'Oswald',sans-serif;
    text-align: center;
    width: 30%;
  `
  const Info = styled(Title)`
    display: block;
    width: 575px;
    padding-top: 10%;
  `
  
  const Footer = styled.footer`
    background-color: black;
    height: 220px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 50px;
  `

  const Sedes = styled.div`
    margin: 0 50px 0 50px;
    width: 450px
  `

  const Contacto = styled(Sedes)`
    margin: 0 50px 0 50px;
    width: 250px;
  `

  const UlSedes = styled.ul`
    padding: 0;
  `
  const H3Footer = styled(H3Title)`
    color: #0799d4;
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
  `

  const LiSedes = styled.li`
    color: white;
    font-family: 'Roboto', sans-serif;
    list-style: none;
    margin-bottom: 10px;
  `


  // Tabla de equipo
  const ContainerEquipo = styled.div`
    display: flex;
    justify-content: center;
    @media only screen and (min-width: 482px) {
      flex-direction: row;
    }
    @media only screen and (max-width: 481px) {
      flex-direction: column;
      align-items: center;
    }
  `

  const InfoEquipo = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 35px;
    
  `

  const TablaEquipoPartidos = styled.div`
    display: flex;
    flew-flow: column;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    flex: 40%;
    height: 400px;
    margin: 20px;
    width: 100%;
    &:hover{
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    @media only screen and (max-width: 481px) {
      flex-direction: column;
      width: 90%;
    }
  `

  const PEquipo = styled.p`
    font-size: 35px;
    font-family: 'Oswald',sans-serif;
  `
  const PEstadistica = styled.p`
    font-size: 25px;
    font-weight: 500;
    margin: 0px 0px 20px 0px;
    font-family: 'Oswald',sans-serif;
  `

  const H2Estadistica = styled.h3`
    color: black;
    margin: 0;
    font-weight: 700;
    font-family: 'Oswald',sans-serif;
    font-size: 20px;
  `
  const EstadisticaEquipo = styled.div`
    margin-top: 20px;
    display: flex;
    flex-flow: column;
    padding-left: 20px;
  `
  const RowJugador = styled(Row)`
    padding: 0;
    margin: auto;
    width: 90%;
    font-weight: 500;
    border-bottom: 1px solid #f1f1f1;
  `
  const ColEquipo = styled(Col)`
    padding: 0;
    text-align: left;
    font-size: 15px;
  `
  const ColEquipoUnit = styled(ColEquipo)`
    text-align: center;
  `

  const ColJugador = styled(Col)`
    padding: 0;
    text-align: left;
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
  const ColJugadorUnit = styled(ColJugador)`
    margin-top: 5px;
    text-align: center;
  `

  const ButtonJugador = styled.a`
    color: inherit;
    text-decoration: none;
  `
  const TituloEquipo = styled.div`
    height: 50px;
    width: 100%;
    font-weight: 700;
    background-color: black;
    font-family: 'Oswald',sans-serif;
    font-size: 25px;
    text-align: center;
    color: white;
    padding: 0;
  `

  const RowEquipo = styled(Row)`
    padding: 0;
    margin: auto;
    width: 90%;
    font-weight: 500;
  `

  const TablaJugadores = styled(TablaEquipoPartidos)`
    width: 80%;
    flex-flow: column;
  `

  const BannerStatsEquipo = styled(TituloEquipo)`
    height: 30px;
    background-color: #525252;
    color: white;
    padding: 0;
  `

  // Tabla de ultimos partidos

  const RowPartidos = styled(RowEquipo)`
    border-bottom: 1px solid #f1f1f1;
  `

  const ContainerUltimosPartidos = styled.div`
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 30px 30px 30px;
    position: relative;
    font-size: 25px;
    font-family: 'Oswald',sans-serif;
    text-align: center;
    width: 90%;
  `
  const TablePartido = styled.div`
    display: flex;
    justify-content: center;
  `
  const Partido = styled.div`
  width: 40%;
    display: flex;
    flex-flow: column;
    align-items: center;
  `

  const CardPartido = styled(TablaEquipoPartidos)`
    width: 370px;
    height: 140px;
    padding: 20px 0px 20px 0px;
    margin-bottom: 30px;
    display: inline-block;
    @media only screen and (min-width: 482px) {
      width: 370px;
    }
    @media only screen and (max-width: 481px) {
      width: 100%;
      margin-left: 0;
    }
  `

  const H3TitleUltPartidos = styled(H3Title)`
    color: black;
    font-family: 'Oswald',sans-serif;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
  `

  const Resultado = styled.div`
    width: 15%;
    @media only screen and (min-width: 482px) {
      font-size: 21px;
    }
    @media only screen and (max-width: 481px) {
      font-size: 19px;
    }
  `
  const ButtonPartido = styled.a`
    color: inherit;
    text-decoration: none;
  `

  const H3TitleStats = styled(H3TitleUltPartidos)`
    font-size: 30px;
  `

  // Mobile viewport
  const JugadorMobile = styled.div`
    @media only screen and (min-width: 482px) {
      display: none; 
    }
  `
  const SedesMobile = styled(Sedes)`
    margin: 0 10px;
    width: 30%;
  `
  const ContactoMobile = styled(Contacto)`
    margin: 0 10px;
    width: 30%;
  `
  const ContainerUltimosPartidosMobile = styled(ContainerUltimosPartidos)`
    flex-flow: column;
    align-items: center;
    padding: 0px;
  `

  const [cardUltimosPartidos, setUltPartidos] = useState();

  
  useEffect(() => {
    setUltPartidos(jugador.ultimosPartidosDivisional.slice(0, 4).map((partido) =>
      <ButtonPartido href={"/partido/" + partido.idPartido  + "/divisional/" + idDivisional}><CardPartido>
        <TablePartido>
          <Partido>
            {partido.equipo1.escudo == null ? <img style={{maxHeight: 60, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 60, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo1.escudo}/>}
            <p>{partido.equipo1.nombre}</p>
          </Partido> 
          <Resultado>
            <p style={{position: "relative", top: "15%"}}>{partido.resultado.golesEquipo1 + " - " + partido.resultado.golesEquipo2}</p>
          </Resultado>
          <Partido>
            {partido.equipo2.escudo == null ? <img style={{maxHeight: 60, paddingRight: "10px"}} src={LVHPlaceholder}/> : <img style={{maxHeight: 60, paddingRight: "10px"}} src={'https://lvh-images.camila.uy/' + partido.equipo2.escudo}/>}
            <p>{partido.equipo2.nombre}</p>
          </Partido>  
        </TablePartido>
      </CardPartido> </ButtonPartido>
    ));
    urlStates.setIdDivisionalData(idDivisional);
  }, [jugador]);

  return (
    <div className="main">
      <JugadorDesktop>
        <Banner style={{backgroundImage: `url(${Background})`}}>
          <Title>
            <Info>
              <H3Title>{jugador.nombre + " " + jugador.apellido}</H3Title>
            </Info>
          </Title>
        </Banner>

        <ContainerEquipo>
          <div style={{display: "flex", flexFlow: "column", width: "50%"}}>
            <H3TitleUltPartidos>JUGADOR</H3TitleUltPartidos>
            <TablaEquipoPartidos>

              <InfoEquipo>
                <img style={{maxHeight: 250, paddingRight: "10px"}} src={Player}/>
                <PEquipo>{jugador.nombre + " " + jugador.apellido}</PEquipo>
              </InfoEquipo>
              
              <div style={{display: "flex", flexFlow: "column", width: "50%"}}>
                <H3TitleStats>STATS ALL TIME</H3TitleStats>
                <EstadisticaEquipo>
                  <H2Estadistica>VECES FIGURA</H2Estadistica>
                  <PEstadistica>{jugador.statsAllTime.mvp}</PEstadistica>
                  <H2Estadistica>PARTIDOS JUGADOS</H2Estadistica>
                  <PEstadistica>{jugador.statsAllTime.partidos.partidosJugados}</PEstadistica>
                  <H2Estadistica>GOLES</H2Estadistica>
                  <PEstadistica>{jugador.statsAllTime.goles.goles}</PEstadistica>
                  <H2Estadistica>PROMEDIO GOLES POR PARTIDO</H2Estadistica>
                  <PEstadistica>{jugador.statsAllTime.goles.promedioGolesPorPartido}</PEstadistica>
                </EstadisticaEquipo>
              </div>
              <div style={{display: "flex", flexFlow: "column", width: "50%"}}>
                <H3TitleStats>STATS DIVISIONAL</H3TitleStats>
                <EstadisticaEquipo>
                  <H2Estadistica>VECES FIGURA</H2Estadistica>
                  <PEstadistica>{jugador.statsDivisional.mvp}</PEstadistica>
                  <H2Estadistica>PARTIDOS JUGADOS</H2Estadistica>
                  <PEstadistica>{jugador.statsDivisional.partidos.partidosJugados}</PEstadistica>
                  <H2Estadistica>GOLES</H2Estadistica>
                  <PEstadistica>{jugador.statsDivisional.goles.goles}</PEstadistica>
                  <H2Estadistica>PROMEDIO GOLES POR PARTIDO</H2Estadistica>
                  <PEstadistica>{jugador.statsDivisional.goles.promedioGolesPorPartido}</PEstadistica>
                </EstadisticaEquipo>
              </div>  

            </TablaEquipoPartidos>
          </div>


          <div style={{display: "flex", flexFlow: "column", width: "50%"}}>
            <H3TitleUltPartidos>Ultimos Partidos</H3TitleUltPartidos>
            <ContainerUltimosPartidos>
              <Col>
                {cardUltimosPartidos}
              </Col>
            </ContainerUltimosPartidos>
          </div>
        </ContainerEquipo>

        <Footer>
          <div style={{width: "350px"}}>
            <img src={`${LVHIcon}`} style={{maxWidth: 200, paddingTop: "2%"}}/>
          </div>
          <Sedes>
            <H3Footer>SEDES</H3Footer>
            <UlSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>El Galpón - Paraguay 2211 casi Freire - Uruguay</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Complejo Lokura Fútbol 7 - Camino Sosa Chaferro entre Luis Batlle Berres y Camino Las Flores Paso de la Arena</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Medio Campo - Avenida Dr. Luis Alberto de Herrera 2992</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Stokolmo - Camino Castro 404 bis</LiSedes>
            </UlSedes>
          </Sedes>
          <Contacto>
            <H3Footer>CONTACTO</H3Footer>
            <UlSedes>
              <LiSedes><img src={`${Email}`} style={{maxWidth: 20, paddingRight: "10px"}}/>lvheventos@gmail.com</LiSedes>
              <LiSedes><img src={`${Phone}`} style={{maxWidth: 20, paddingRight: "10px"}}/>+598 98 547 535</LiSedes>
            </UlSedes>
          </Contacto>
        </Footer>
      </JugadorDesktop>

      <JugadorMobile>
      <Banner style={{backgroundImage: `url(${Background})`}}>
          <Title>
            <Info>
              <H3Title>{jugador.nombre + " " + jugador.apellido}</H3Title>
            </Info>
          </Title>
        </Banner>

        <ContainerEquipo>
          <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            <H3TitleUltPartidos>JUGADOR</H3TitleUltPartidos>
            <TablaEquipoPartidos>

              <InfoEquipo>
                <img style={{maxHeight: 185, paddingRight: "10px"}} src={Player}/>
                <PEquipo>{jugador.nombre + " " + jugador.apellido}</PEquipo>
              </InfoEquipo>
              
              <div style={{display: "flex", flexFlow: "column", width: "100%"}}>
                <H3TitleStats>STATS ALL TIME</H3TitleStats>
                <EstadisticaEquipo>
                  <H2Estadistica>VECES FIGURA</H2Estadistica>
                  <PEstadistica>{jugador.statsAllTime.mvp}</PEstadistica>
                  <H2Estadistica>PARTIDOS JUGADOS</H2Estadistica>
                  <PEstadistica>{jugador.statsAllTime.partidos.partidosJugados}</PEstadistica>
                  <H2Estadistica>GOLES</H2Estadistica>
                  <PEstadistica>{jugador.statsAllTime.goles.goles}</PEstadistica>
                  <H2Estadistica>PROMEDIO GOLES POR PARTIDO</H2Estadistica>
                  <PEstadistica>{jugador.statsAllTime.goles.promedioGolesPorPartido}</PEstadistica>
                </EstadisticaEquipo>
              </div>
              <div style={{display: "flex", flexFlow: "column", width: "100%"}}>
                <H3TitleStats>STATS DIVISIONAL</H3TitleStats>
                <EstadisticaEquipo>
                  <H2Estadistica>VECES FIGURA</H2Estadistica>
                  <PEstadistica>{jugador.statsDivisional.mvp}</PEstadistica>
                  <H2Estadistica>PARTIDOS JUGADOS</H2Estadistica>
                  <PEstadistica>{jugador.statsDivisional.partidos.partidosJugados}</PEstadistica>
                  <H2Estadistica>GOLES</H2Estadistica>
                  <PEstadistica>{jugador.statsDivisional.goles.goles}</PEstadistica>
                  <H2Estadistica>PROMEDIO GOLES POR PARTIDO</H2Estadistica>
                  <PEstadistica>{jugador.statsDivisional.goles.promedioGolesPorPartido}</PEstadistica>
                </EstadisticaEquipo>
              </div>  

            </TablaEquipoPartidos>
          </div>


          <div style={{display: "flex", flexFlow: "column", width: "90%"}}>
            <H3TitleUltPartidos>Ultimos Partidos</H3TitleUltPartidos>
            <ContainerUltimosPartidosMobile>
              <Col>
                {cardUltimosPartidos}
              </Col>
            </ContainerUltimosPartidosMobile>
          </div>
        </ContainerEquipo>

        <Footer style={{paddingTop: "20px"}}>
          <div style={{width: "30%", margin: "0 10px"}}>
            <img src={`${LVHIcon}`} style={{maxWidth: 90, paddingTop: "2%"}}/>
          </div>
          <SedesMobile>
            <H3Footer>SEDES</H3Footer>
            <UlSedes style={{fontSize: "9px"}}>
              <LiSedes style={{marginBottom: "15px"}}><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>El Galpón - Paraguay 2211 casi Freire - Uruguay</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Complejo Lokura Fútbol 7 - Camino Sosa Chaferro entre Luis Batlle Berres y Camino Las Flores Paso de la Arena</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Medio Campo - Avenida Dr. Luis Alberto de Herrera 2992</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Stokolmo - Camino Castro 404 bis</LiSedes>
            </UlSedes>
          </SedesMobile>
          <ContactoMobile>
            <H3Footer>CONTACTO</H3Footer>
            <UlSedes style={{fontSize: "8px"}}>
              <LiSedes><img src={`${Email}`} style={{maxWidth: 10, paddingRight: "10px"}}/>lvheventos@gmail.com</LiSedes>
              <LiSedes><img src={`${Phone}`} style={{maxWidth: 10, paddingRight: "10px"}}/>+598 98 547 535</LiSedes>
            </UlSedes>
          </ContactoMobile>
        </Footer>
      </JugadorMobile>
    </div>
  );
}

export default Jugador;

export async function loader({params}) {
  const axios = require('axios');
  let jugador = [];
  jugador.push(params.idDivisional);

  const config = {
      method: 'get',
      url: 'https://lvh.camila.uy/jugador/' + params.idJugador + '/divisional/' + params.idDivisional,
      headers: {
        'Accept': 'application/json'
      }
  };

  await axios(config)
    .then(function (response) {
      jugador.push(response.data);
    })
  return jugador;
}