import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import LVHIcon from '../assets/img/lvh.png';
import Arrow from '../assets/img/down-arrow.png';
import MenuIcon from '../assets/img/menuIcon.png';
import Reglamento from '../assets/reglamento.pdf';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Header({urlStates}) {
  const [dropdownState, setDropdownState] = useState(false);

  const [dropdownMobileState, setDropdownMobileState] = useState(false);

  const [dropdownDivisionalState, setDropdownDivisionalState] = useState(false);

  const [torneos, setTorneos] = useState([]);

  let location = useLocation();

  const HeaderDesktop = styled.header`
    background-color: rgb(53, 54, 60);
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    color: rgb(255, 255, 255);
    height: 80px;
    z-index: 1;
    position: fixed;
    top: 0;
    width: 100%; 
    @media only screen and (max-width: 480px) {
      display: none; 
    }
  `
  const HeaderMobile = styled.header`
    background-color: rgb(53, 54, 60);
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    color: rgb(255, 255, 255);
    height: 80px;
    z-index: 1;
    position: fixed;
    top: 0;
    width: 100%; 
    @media only screen and (min-width: 480px) {
      display: none; 
    }
  `
  const MenuUl = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    float: right;
    padding-right: 10%;
    padding-top: 1%;
  `
  const MenuUlMobile = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    padding-right: 10%;
    padding-top: 1%;
  `
  const Li = styled.li`
    display: inline-block;
  `
  const DropDownUl = styled.ul`
    ${dropdownState ? "display: block;" : "display: none;"}
    color: rgb(255,255,255);
    position: absolute;
    width: 200px;
    padding: 5px 20px 10px 20px;
    background-color: white;
    font-size: 14px;
    line-height: 1.71429;
    letter-spacing: 0.025em;
    font-family: 'Kanit', sans-serif;
    margin-top: 0;
    border: 1px solid #e1e1e1;
    overflow-y: auto; 
    max-height:400px;
  `
  const DropDownUlMobile = styled.ul`
    ${dropdownState ? "display: block;" : "display: none;"}
    color: rgb(255,255,255);
    width: 100%;
    padding: 5px 20px 10px 20px;
    font-size: 14px;
    line-height: 1.71429;
    letter-spacing: 0.025em;
    font-family: 'Kanit', sans-serif;
    margin-top: 0;
    overflow-y: auto; 
    max-height:400px;
  `
  const LiTorneo = styled.li`
    display: block;
    background-color: white;
    padding: 10px 10px 5px 10px;
    color: black;
    text-align: left;
    border-bottom: solid 1px #f1f1f1;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
    &:hover{
      &:before{
        background-image: url('http://placehold.it/16x16');
        background-position: left center;
        background-repeat: no-repeat;
        transition: all 0.3s;
        margin-left: 0px;
        padding-right: 5px;
        opacity: 1;
      }
    }
    &:before{
      transition: all 0.3s;
      opacity: 0;
      margin-left: -20px;
      content: '➧';
      color: rgb(14, 92, 201);
    }
  `
  const LiTorneoMobile = styled(LiTorneo)`
    display: block;
    background-color: rgb(53, 54, 60);
    padding: 10px 10px 5px 10px;
    color: white;
    text-align: left;
    border-bottom: none;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
  `
  const LiDivisional = styled.li`
    display: block;
    background-color: white;
    padding: 10px 10px 5px 10px;
    color: black;
    text-align: left;
    border-bottom: solid 1px #f1f1f1;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
    &:hover{
      &:before{
        background-image: url('http://placehold.it/16x16');
        background-position: left center;
        background-repeat: no-repeat;
        transition: all 0.3s;
        margin-left: 0px;
        padding-right: 5px;
        opacity: 1;
      }
    }
    &:before{
      transition: all 0.3s;
      opacity: 0;
      margin-left: -20px;
      content: '➧';
      color: rgb(14, 92, 201);
    }
  `
  const LiDivisionalMobile = styled(LiDivisional)`
    display: block;
    background-color: rgb(53, 54, 60);
    padding: 10px 10px 5px 10px;
    color: white;
    text-align: left;
    border-bottom: none;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
  `
  const [liTorneos, setLis] = useState(<LiTorneo>No hay torneos actualmente</LiTorneo>);
  const [liDivisionales, setLisDiv] = useState(<LiDivisional>No hay divisionales actualmente</LiDivisional>);

  const [liTorneosMobile, setLisMobile] = useState(<LiTorneoMobile>No hay torneos actualmente</LiTorneoMobile>);
  const [liDivisionalesMobile, setLisDivMobile] = useState(<LiDivisionalMobile>No hay divisionales actualmente</LiDivisionalMobile>);

  const MenuButton = styled.a`
    display: block;
    color: white;
    text-align: center;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
  `
  const MenuButtonMobile = styled(MenuButton)`
    text-align: left;
  `
  const ButtonDivisional = styled.a`
    color: inherit;
    text-decoration: none;
  `
  const MenuDropdown = styled.div`
    display: block;
    color: white;
    text-align: center;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
  `
  const Logo = styled.div`
    display: inline-block;
    padding-top: 5px;
  `
  const DropMenuMobile = styled.div`
    background-color: rgb(53, 54, 60);
    ${dropdownMobileState ? "display: block;" : "display: none;"}
  `
  const LiMobile = styled(Li)`
    display: block;
    padding-left: 5%;
  `

  const dropdownHandler = () => {
    setLis(torneos.map((torneo, index) => <LiTorneo key={torneo.id} onClick={coscu => dropdownDivisionalHandler(torneo.divisionales)}>{torneo.nombre}</LiTorneo>));
    setLisDiv();
    setDropdownDivisionalState(false);
    setDropdownState(!dropdownState);
    console.log('DropdownState:' + dropdownState);
  };  

  const dropdownMobileHandler = () => {
    setLisMobile(torneos.map((torneo) => <LiTorneoMobile key={torneo.id} onClick={coscu => dropdownDivisionalHandler(torneo.divisionales)}>{torneo.nombre}</LiTorneoMobile>));
    setLisDivMobile();
    setDropdownDivisionalState(!dropdownDivisionalState);
    setDropdownMobileState(!dropdownMobileState);
  };

  const dropdownRefreshMobileHandler = () => {
    setLisMobile(torneos.map((torneo, index) => <LiTorneoMobile key={torneo.id} onClick={coscu => dropdownDivisionalHandler(torneo.divisionales)}>{torneo.nombre}</LiTorneoMobile>));
    setLisDivMobile();
    setDropdownState(!dropdownState);
    setDropdownDivisionalState(!dropdownDivisionalState);
    console.log('DropdownState:' + dropdownState);
  };

  const dropdownDivisionalHandler = (divisionales) => {
    setLisDiv(divisionales.map((divisional, index) => <LiDivisional key={divisional.idDivisional}><ButtonDivisional href={"/divisional/" + divisional.idDivisional}>{divisional.nombre}</ButtonDivisional></LiDivisional>));
    setLisDivMobile(divisionales.map((divisional, index) => <LiDivisionalMobile key={divisional.idDivisional}><ButtonDivisional href={"/divisional/" + divisional.idDivisional}>{divisional.nombre}</ButtonDivisional></LiDivisionalMobile>));
    setDropdownDivisionalState(true);
    setDropdownState(true);
    setLis();
    setLisMobile();
    console.log('DropdownStateDivisional:' + dropdownDivisionalState);
  }; 

  // Use effect de llamada API
  useEffect(() => {
    const axios = require('axios');

    const config = {
        method: 'get',
        url: 'https://lvh.camila.uy/torneo',
        headers: {
          'Accept': 'application/json'
        }
    };

    axios(config)
      .then(function (response) {
        setTorneos(response.data);
      })
      .catch(function (error) {
          console.log(error);
      });
  }, []);

  // Use effect de mapeo de torneos
  useEffect(() => {
    setLis(torneos.map((torneo) => <LiTorneo key={torneo.id} onClick={coscu => dropdownDivisionalHandler(torneo.divisionales)}>{torneo.nombre}</LiTorneo>));
    setLisMobile(torneos.map((torneo) => <LiTorneoMobile key={torneo.id} onClick={coscu => dropdownDivisionalHandler(torneo.divisionales)}>{torneo.nombre}</LiTorneoMobile>));
  }, [torneos]);

  return (
    <div className="main">
      <HeaderDesktop>
        <Container>
            <Logo>
              <img src={`${LVHIcon}`} style={{maxWidth: 85}}/>
            </Logo>
            <MenuUl>
                <Li><MenuButton href="/inicio">Inicio</MenuButton></Li>
                <Li><MenuDropdown onClick={dropdownHandler}>Torneos<img src={`${Arrow}`} style={{maxWidth: 12, paddingBottom: "4px", paddingLeft: "10px"}}/></MenuDropdown>
                <div style={{position: "absolute"}}>
                  <DropDownUl>
                    {liTorneos}
                    {liDivisionales}
                  </DropDownUl>
                </div>
                </Li>
                {location.pathname != "/inicio/" && location.pathname != "/" ? <Li><MenuButton href={"/estadisticas/" + urlStates.idDivisionalData}>Estadísticas</MenuButton></Li>: null}
                {location.pathname != "/inicio/" && location.pathname != "/" ? <Li><MenuButton href={"/fairplay/" + urlStates.idDivisionalData}>Fair Play</MenuButton></Li>: null} 
                {location.pathname != "/inicio/" && location.pathname != "/" ? <Li><MenuButton href={"/fechas/" + urlStates.idDivisionalData}>Fechas</MenuButton></Li>: null}      
                <Li><MenuButton href={Reglamento} target = "_blank">Reglamento</MenuButton></Li>
            </MenuUl>
        </Container>
      </HeaderDesktop>
      <HeaderMobile>
        <Container>
          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "15px"}}>
            <Logo>
              <img src={`${LVHIcon}`} style={{maxWidth: 80}}/>
            </Logo>
            <a class="iconMenu" onClick={dropdownMobileHandler} style={{margin: "3%", width: "10%"}}><img style={{width: "80%", paddingBottom: "1%"}} src={MenuIcon}/></a>
          </div>
        </Container>
        <DropMenuMobile>
          <MenuUlMobile>
              <LiMobile><MenuButton href="/inicio" style={{textAlign: "left"}}>Inicio</MenuButton></LiMobile>
              <LiMobile><MenuDropdown onClick={dropdownRefreshMobileHandler} style={{textAlign: "left"}}>Torneos<img src={`${Arrow}`} style={{maxWidth: 12, paddingBottom: "4px", paddingLeft: "10px"}}/></MenuDropdown>
                <DropDownUlMobile>
                  {liTorneosMobile}
                  {liDivisionalesMobile}
                </DropDownUlMobile>
              </LiMobile>
              {location.pathname != "/inicio/" && location.pathname != "/" ? <LiMobile><MenuButtonMobile href={"/estadisticas/" + urlStates.idDivisionalData}>Estadísticas</MenuButtonMobile></LiMobile>: null}
              {location.pathname != "/inicio/" && location.pathname != "/" ? <LiMobile><MenuButtonMobile href={"/fairplay/" + urlStates.idDivisionalData}>Fair Play</MenuButtonMobile></LiMobile>: null} 
              {location.pathname != "/inicio/" && location.pathname != "/" ? <LiMobile><MenuButtonMobile href={"/fechas/" + urlStates.idDivisionalData}>Fechas</MenuButtonMobile></LiMobile>: null}      
              <LiMobile><MenuButton href={Reglamento} target = "_blank" style={{textAlign: "left"}}>Reglamento</MenuButton></LiMobile>
          </MenuUlMobile>
        </DropMenuMobile>
      </HeaderMobile>
      <div style={{height: "80px"}}/>
    </div>
  );
}

export default Header;
