import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled, { isStyledComponent } from 'styled-components';
import { useState, useEffect } from 'react';
import Background from '../assets/img/stadium.jpg';
import LVHIconWhite from '../assets/img/lvh_white.png';
import LVHIcon from '../assets/img/lvh.png';
import FormIcon from '../assets/img/form.png';
import Grass from '../assets/img/grass.jpg';
import HandMobile from '../assets/img/hand_mobile.png';
import GooglePlay from '../assets/img/google_play.png';
import Email from '../assets/img/email.png';
import Ball from '../assets/img/ball.png';
import Phone from '../assets/img/phone-call.png';
import Field from '../assets/img/field.png';
import Foto1 from '../assets/img/foto-01.jpg';
import Foto2 from '../assets/img/foto-02.jpg';
import axios from 'axios';

function Inicio() {
  const InicioDesktop = styled.div`
    @media only screen and (max-width: 480px) {
      display: none; 
    }
  `
  const GlobalP = styled.p`
    display: block;
    margin: 0;
    color: white;
  `
  const H3Title = styled.h3`
    color: white;
    margin: 0;
  `
  const Banner = styled.div`
    display: block;
    height: 300px;
    background-position-y: 600px;
  `
  const Title = styled.div`
    height: auto;
    margin: 0 auto;
    display: flex;
    padding: 10px;
    position: relative;
    font-size: 25px;
    font-family: 'Oswald',sans-serif;
    text-align: center;
    width: 30%;
  `
  const Info = styled(Title)`
    display: block;
    width: 100%;
    padding-top: 14%;
  `
  const DownloadAppText = styled(Info)`
    display: block;
    width: 100%;
    padding-top: 5%;
  `
  const DownloadApp = styled(Banner)`
    display: flex;
    padding-right: 25%;
    padding-left: 25%;
  `
  const ContainerDownload = styled.div`
    display: flex;
    justify-content: center;
  `
  const Inscripcion = styled(Banner)`
    height: 150px;
    background-color: black;
  `
  const InfoInscripcion= styled(Info)`
    padding-top: 8%;
  `
  const ContainerInscripcion = styled(Title)`
    width: 15%;
    padding-top: 1.5%;
  `
  const ContainerFotosMapa = styled(Title)`
    width: 100%;
    padding: 0;
  `
  const Fotos = styled.div`
    width: 50%;
    display: block;
    padding: 0;
    height: 21vw;
  `

  const BannerFotos = styled(Banner)`
    height: 80px;
    width: 100%;
    background-color: #fb7f7f;
    color: white;
    padding: 0;
  `
  const InfoFotos = styled(Info)`
    padding: 2%;
  `

  const FotoImg = styled.div`
    width: 100%;
    object-fit: cover;
    transition: all .3s ease;
    -webkit-transition: all 0.3s ease-in-out;
    height: 16.8vw;
    background-position-x: center;
    &:hover {
      -webkit-transform: scale(1.2,1.2); 
      transform: scale(1.2,1.2);
      -webkit-transition: all 0.3s ease-in-out;
    }
  `

  const BannerMapa = styled(BannerFotos)`
    background-color: #007bff;
  `
  const InfoSede = styled(Info)`
    padding-top: 6%;
  `

  const H3Sede = styled(H3Title)`
    color: black;
    font-family: 'Oswald',sans-serif;
  `

  const PSede = styled(GlobalP)`
    color: black;
  `

  const Footer = styled.footer`
    background-color: black;
    height: 220px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 50px;
  `

  const Sedes = styled.div`
    margin: 0 50px 0 50px;
    width: 450px
  `

  const Contacto = styled(Sedes)`
    margin: 0 50px 0 50px;
    width: 250px;
  `

  const UlSedes = styled.ul`
    padding: 0;
  `
  const H3Footer = styled(H3Title)`
    color: #0799d4;
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
  `

  const LiSedes = styled.li`
    color: white;
    font-family: 'Roboto', sans-serif;
    list-style: none;
    margin-bottom: 10px;
  `
  const Wrapper = styled.div`
    overflow: hidden;
    height: 16.8vw;
    width: 50%;
    &:hover {
      filter: greyscale;
    }
  `
  // Mobile viewport
  const InicioMobile = styled.div`
    @media only screen and (min-width: 480px) {
      display: none; 
    }
  `

  const TitleMobile = styled(Title)`
    width: 100%;
  `
  const InfoMobile = styled(Info)`
    padding-top: 23%;
  `
  const ContainerInscripcionMobile = styled(ContainerInscripcion)`
    width: 70%;
    padding-top: 6%;
  `
  const DownloadAppMobile = styled(DownloadApp)`
    padding: 0px;
    align-items: center;
  `
  const WrapperMobile = styled(Wrapper)`
    height: 50vw;
    width: 100%;
  `
  const FotoImgMobile = styled(FotoImg)`
    height: 50vw;
  `
  const FotosMobile = styled(Fotos)`
    width: 100%;
    height: auto;
  `
  const ContainerFotosMapaMobile = styled(ContainerFotosMapa)`
    flex-direction: column;
  `
  const InfoSedeMobile = styled(InfoSede)`
    display: flex;
    flex-direction: row;
    padding: 5%;
  `
  const SedesMobile = styled(Sedes)`
    margin: 0 10px;
    width: 30%;
  `
  const ContactoMobile = styled(Contacto)`
    margin: 0 10px;
    width: 30%;
  `
  // Use effect de llamada API
  useEffect(() => {

  }, []);

  return (
    <div className="main">
      <InicioDesktop>
        <Banner style={{backgroundImage: `url(${Background})`}}>
          <Title>
            <div style={{width: "350px"}}>
              <img src={`${LVHIconWhite}`} style={{maxWidth: 250, paddingTop: "10%"}}/>
            </div>
            <Info>
              <H3Title>LVH EVENTOS</H3Title>
              <GlobalP>Gestión de eventos deportivos</GlobalP>
            </Info>
          </Title>
        </Banner>

        <Inscripcion>
          <ContainerInscripcion>
            <div>
              <img src={`${FormIcon}`} style={{maxWidth: 100, paddingTop: "14%"}}/>
            </div>
            <InfoInscripcion>
              <H3Title>INSCRIBITE ACÁ</H3Title>
            </InfoInscripcion>
          </ContainerInscripcion>
        </Inscripcion>
        
        <DownloadApp style={{backgroundImage: `url(${Grass})`}}>
          <DownloadAppText>
            <H3Title>DESCARGÁ NUESTRA APP</H3Title>
            <GlobalP>Y SEGUÍ EL TORNEO DESDE TU CELULAR</GlobalP>
            <ContainerDownload>
              <img src={`${GooglePlay}`} style={{maxWidth: 200, paddingTop: "5%", marginRight: "10px"}}/>
            </ContainerDownload>
          </DownloadAppText>
          <div style={{width: "200px"}}>
            <img src={`${HandMobile}`} style={{maxWidth: 200, paddingTop: "10%"}}/>
          </div>
        </DownloadApp>

        <ContainerFotosMapa>
          <Fotos>
            <BannerFotos>
              <InfoFotos>
                <H3Title>FOTOS</H3Title>
              </InfoFotos>
            </BannerFotos>
            <div style={{display: "flex", height: "16.8vw"}}>
              <Wrapper><a href="https://www.instagram.com/lvheventos/"><FotoImg style={{backgroundImage: `url(${Foto1})`}}/></a></Wrapper>
              <Wrapper><a href="https://www.instagram.com/lvheventos/"><FotoImg style={{backgroundImage: `url(${Foto2})`}}/></a></Wrapper>   
            </div>
          </Fotos>
          <Fotos>
            <BannerMapa>
              <InfoFotos>
                <H3Title>DONDE JUGAMOS</H3Title>
              </InfoFotos>
            </BannerMapa>
            <div style={{display: "flex"}}>
              <InfoSede>
                <img src={`${Field}`} style={{maxWidth: 100}}/>
                <H3Sede>EL GALPÓN</H3Sede>
                <PSede> Paraguay 2211 esq. Freire</PSede>
                <PSede style={{fontSize: 16}}> Montevideo - Uruguay</PSede>
              </InfoSede>
              <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.680819364514!2d-56.19685068476271!3d-34.88936048038646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f8022e237dbc3%3A0x473843e1099815c7!2sCanchas%20El%20Galp%C3%B3n!5e0!3m2!1ses!2suy!4v1663297933720!5m2!1ses!2suy" style={{width: "30vw", height: "16vw", border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </Fotos>
        </ContainerFotosMapa>

        <Footer>
          <div style={{width: "350px"}}>
            <img src={`${LVHIcon}`} style={{maxWidth: 200, paddingTop: "2%"}}/>
          </div>
          <Sedes>
            <H3Footer>SEDES</H3Footer>
            <UlSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>El Galpón - Paraguay 2211 casi Freire - Uruguay</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Complejo Lokura Fútbol 7 - Camino Sosa Chaferro entre Luis Batlle Berres y Camino Las Flores Paso de la Arena</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Medio Campo - Avenida Dr. Luis Alberto de Herrera 2992</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 15, paddingRight: "10px"}}/>Stokolmo - Camino Castro 404 bis</LiSedes>
            </UlSedes>
          </Sedes>
          <Contacto>
            <H3Footer>CONTACTO</H3Footer>
            <UlSedes>
              <LiSedes><img src={`${Email}`} style={{maxWidth: 20, paddingRight: "10px"}}/>lvheventos@gmail.com</LiSedes>
              <LiSedes><img src={`${Phone}`} style={{maxWidth: 20, paddingRight: "10px"}}/>+598 98 547 535</LiSedes>
            </UlSedes>
          </Contacto>
        </Footer>
      </InicioDesktop>
      <InicioMobile>
        <Banner style={{backgroundImage: `url(${Background})`}}>
          <TitleMobile>
            <div style={{width: "350px"}}>
              <img src={`${LVHIconWhite}`} style={{maxWidth: 130, paddingTop: "50%"}}/>
            </div>
            <InfoMobile>
              <H3Title>LVH EVENTOS</H3Title>
              <GlobalP>Gestión de eventos deportivos</GlobalP>
            </InfoMobile>
          </TitleMobile>
        </Banner>

        <Inscripcion>
          <ContainerInscripcionMobile>
            <div>
              <img src={`${FormIcon}`} style={{maxWidth: 100, paddingTop: "14%"}}/>
            </div>
            <InfoInscripcion>
              <H3Title>INSCRIBITE ACÁ</H3Title>
            </InfoInscripcion>
          </ContainerInscripcionMobile>
        </Inscripcion>
        
        <DownloadAppMobile style={{backgroundImage: `url(${Grass})`}}>
          <DownloadAppText>
            <H3Title style={{fontSize: "20px"}}>DESCARGÁ NUESTRA APP</H3Title>
            <GlobalP style={{fontSize: "18px"}}>Y SEGUÍ EL TORNEO DESDE TU CELULAR</GlobalP>
            <ContainerDownload>
              <img src={`${GooglePlay}`} style={{maxWidth: 150, paddingTop: "5%", marginRight: "10px"}}/>
            </ContainerDownload>
          </DownloadAppText>
          <div style={{width: "180px"}}>
            <img src={`${HandMobile}`} style={{maxWidth: 180, paddingTop: "30%"}}/>
          </div>
        </DownloadAppMobile>

        <ContainerFotosMapaMobile>
          <FotosMobile>
            <BannerFotos style={{height: "60px"}}>
              <InfoFotos>
                <H3Title>FOTOS</H3Title>
              </InfoFotos>
            </BannerFotos>
            <div style={{display: "flex"}}>
              <WrapperMobile><a href="https://www.instagram.com/lvheventos/"><FotoImgMobile style={{backgroundImage: `url(${Foto1})`}}/></a></WrapperMobile>
              <WrapperMobile><a href="https://www.instagram.com/lvheventos/"><FotoImgMobile style={{backgroundImage: `url(${Foto2})`}}/></a></WrapperMobile>
            </div>
          </FotosMobile>
          <FotosMobile>
            <BannerMapa style={{height: "60px"}}>
              <InfoFotos>
                <H3Title>DONDE JUGAMOS</H3Title>
              </InfoFotos>
            </BannerMapa>
            <div style={{display: "flex", flexDirection: "column"}}>
              <InfoSedeMobile>
                <img src={`${Field}`} style={{maxWidth: 100}}/>
                <div style={{width: "80%"}}>
                  <H3Sede>EL GALPÓN</H3Sede>
                  <PSede> Paraguay 2211 esq. Freire</PSede>
                  <PSede style={{fontSize: 16}}> Montevideo - Uruguay</PSede>
                </div>
              </InfoSedeMobile>
              <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.680819364514!2d-56.19685068476271!3d-34.88936048038646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f8022e237dbc3%3A0x473843e1099815c7!2sCanchas%20El%20Galp%C3%B3n!5e0!3m2!1ses!2suy!4v1663297933720!5m2!1ses!2suy" style={{width: "100%", height: "70vw", border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </FotosMobile>
        </ContainerFotosMapaMobile>

        <Footer style={{paddingTop: "20px"}}>
          <div style={{width: "30%", margin: "0 10px"}}>
            <img src={`${LVHIcon}`} style={{maxWidth: 90, paddingTop: "2%"}}/>
          </div>
          <SedesMobile>
            <H3Footer>SEDES</H3Footer>
            <UlSedes style={{fontSize: "9px"}}>
              <LiSedes style={{marginBottom: "15px"}}><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>El Galpón - Paraguay 2211 casi Freire - Uruguay</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Complejo Lokura Fútbol 7 - Camino Sosa Chaferro entre Luis Batlle Berres y Camino Las Flores Paso de la Arena</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Medio Campo - Avenida Dr. Luis Alberto de Herrera 2992</LiSedes>
              <LiSedes><img src={`${Ball}`} style={{maxWidth: 8, paddingRight: "10px"}}/>Stokolmo - Camino Castro 404 bis</LiSedes>
            </UlSedes>
          </SedesMobile>
          <ContactoMobile>
            <H3Footer>CONTACTO</H3Footer>
            <UlSedes style={{fontSize: "8px"}}>
              <LiSedes><img src={`${Email}`} style={{maxWidth: 10, paddingRight: "10px"}}/>lvheventos@gmail.com</LiSedes>
              <LiSedes><img src={`${Phone}`} style={{maxWidth: 10, paddingRight: "10px"}}/>+598 98 547 535</LiSedes>
            </UlSedes>
          </ContactoMobile>
        </Footer>
      </InicioMobile>
    </div>
  );
}

export default Inicio;
